<template>
  <div class="page">
    <van-list
      v-model="loading"
      :error.sync="listError"
      error-text="请求失败，点击重新加载"
      finished-text="到底了哦~"
      :finished="finished"
      @load="getMyQaList(1)"
    >
      <question-item
        v-for="(item, index) in dataList"
        :key="index"
        :qa="item"
        @click.native="
          $router.push({ name: 'QuestionAnswer', query: { id: item.qaId } })
        "
      ></question-item>
    </van-list>
  </div>
</template>

<script>
import QuestionItem from "./QuestionItem.vue";
import { List } from "vant";
import { getMyQaList } from "@/api/qa";
import to from "@/utils/to";

export default {
  components: {
    QuestionItem,
    [List.name]: List,
  },
  props: {
    isAnswer: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      pageNum: 1, // 分页
      listError: false,
      finished: false,
      dataList: [],
    };
  },
  methods: {
    async getMyQaList() {
      this.loading = true;
      let [err, res] = await to(getMyQaList(this.isAnswer, this.pageNum, 10));
      this.loading = false;
      if (err) {
        this.listError = true;
        this.finished = true;
        return;
      }
      if (res.rows.length === 0) {
        this.finished = true;
        return;
      }
      this.dataList = this.dataList.concat(res.rows);
      this.pageNum = res.pageNum + 1;
    },
  },
};
</script>

<style scoped>
.page {
  height: calc(100vh - 32px);
  background: #f3f7fa;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
