<template>
  <van-tabs v-model="activeName" color="#FFC83E" line-width="22px">
    <van-tab title="已解答" name="answer">
      <question-list :isAnswer="1"></question-list>
    </van-tab>
    <van-tab title="未解答" name="unanswer">
      <question-list :isAnswer="0"></question-list>
    </van-tab>
  </van-tabs>
</template>

<script>
import QuestionList from "./QuestionList.vue";
import { Tab, Tabs } from "vant";

export default {
  components: {
    QuestionList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: "answer",
    };
  },
  methods: {},
};
</script>

<style scoped></style>